@import '../utils/type.scss';
@import '../utils/shadow.scss';

.card {
  display: flex;
  flex-direction: column;
  margin-right: calc(var(--gw) * 1.25);
  margin-bottom: calc(var(--gw) * 1.25);
  background-color: var(--white);
  box-shadow: shadow(1);
}

.cardAnimated {
  transition-delay: 0.1s;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  cursor: pointer;
  &:hover {
    transform: translateY(-4px);
    @include shadow(2);
  }
}

.cardBody {
  @include textSmall;
  padding: var(--gw) calc(var(--gw) * 1.5);
}

.cardTitle {
  @include text;
  font-weight: 500;
  margin-bottom: calc(var(--gw) * 0.75);
}
