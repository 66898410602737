:root {
  font-size: 16px;
}

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  } @else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $value: $value / $number;
    }
  }

  @return $value;
}

$base-font-size: 1rem;
$base-line-height: $base-font-size * 1.3;
$scaling-factor: 1.125; // major second

@mixin typeScale($type-scaling: 0, $height-scaling: 1) {
  font-size: pow($scaling-factor, $type-scaling) * $base-font-size;
  line-height: $base-line-height * $height-scaling;
}

@mixin h1 {
  @include typeScale(6, 2);
}

@mixin h2 {
  @include typeScale(5, 2);
}

@mixin h3 {
  @include typeScale(4, 1.8);
}

@mixin h4 {
  @include typeScale(3, 1.5);
}

@mixin h5 {
  @include typeScale(2, 1.5);
}

@mixin h6 {
  @include typeScale(1, 1.3);
}

@mixin text {
  @include typeScale(0, 1.2);
}

@mixin textSmall {
  @include typeScale(-1, 1.05);
}

@mixin textTiny {
  @include typeScale(-2, 1);
}
