.list-group {
  list-style:none;
  width:100%;
  margin:0;
  padding:0;
  @include textSmall;
  > li {
    border-bottom:1px solid var(--border-default);
    padding:var(--gw);
    display: flex;
    justify-content: space-between;
    span {
      font-weight:300;
    }

    span:nth-child(2) {
      text-align:right;
      font-weight:400;
    }
  }
  > li:last-child {
    border-bottom: none;
  }
}
