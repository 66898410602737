@import './variables.scss';

@mixin grid($columns, $gutter-width) {
  [class^='col-'],
  [class*=' col-'] {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 0;
    margin: calc(#{$gutter-width} / 2) calc(#{$gutter-width} / 2);
  }

  @for $i from 1 through $columns {
    .col-xs-#{$i} {
      width: calc(calc(100% / #{$columns} * #{$i}) - calc(#{$gutter-width}));
    }
  }

  @media screen and (min-width: 20rem) {
    @for $i from 1 through $columns {
      .col-sm-#{$i} {
        width: calc(calc(100% / #{$columns} * #{$i}) - calc(#{$gutter-width}));
      }
    }
  }

  @media screen and (min-width: 30rem) {
    @for $i from 1 through $columns {
      .col-md-#{$i} {
        width: calc(calc(100% / #{$columns} * #{$i}) - calc(#{$gutter-width}));
      }
    }
  }

  @media screen and (min-width: 60rem) {
    @for $i from 1 through $columns {
      .col-lg-#{$i} {
        width: calc(calc(100% / #{$columns} * #{$i}) - calc(#{$gutter-width}));
      }
    }
  }
}

.row.demo {
  div {
    background-color: rgba(255, 192, 203, 0.48);
    min-height:2rem;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  @include grid(12, $gw);
}

.row.space-between {
  justify-content: space-between;
}