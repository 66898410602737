.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 30rem;
  text-align: center;
  padding: calc(var(--gw) * 4) 0;

  &__icon {
    color: var(--grey-700);
    margin-bottom: calc(var(--gw));
    svg {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &__title {
    margin-bottom: calc(var(--gw) / 1.5);
    font-size: 1.125rem;
    line-height: 1.69rem;
    font-weight: 500;
    color: var(--coolGrey-600);
  }

  &__body {
    @include textSmall;
    color: var(--grey-900);
    margin-bottom: var(--gw);
  }

  &__text {
    @include textTiny;
    color: var(--grey-900);
    margin-bottom: var(--gw);
  }

  a, a:visited, a:focus {
    font-weight: bold;
    color: var(--blue-700);
  }

  &__help {
    margin: calc(var(--gw) * 1.5) var(--gw) var(--gw);
    padding: 0 var(--gw);
    color: var(--grey-900);
    @include textTiny;
    &-button{
      ds-button[version="monochrome"] button {
        background-color: white;
      }
    }
  }
}
