.tag {
  display: inline-block;
  @include textTiny;
  padding: calc(var(--gw) * 0.2) calc(var(--gw) * 0.4);
  background-color: var(--grey-300);
  color: var(--black);
  border-radius: var(--radius);
  border: 1px solid var(--grey-500);
  &-label {
    font-weight: normal;
  }
}

.tag.tag-primary {
  background-color: var(--purple-200);
  color: var(--purple-800);
  border-radius: var(--radius);
  border: 1px solid var(--purple-300);
}

.tag.tag-info {
  background-color: var(--blue-200);
  color: var(--blue-800);
  border-radius: var(--radius);
  border: 1px solid var(--blue-300);
}

.tag.tag-success {
  background-color: var(--green-200);
  color: var(--green-800);
  border-radius: var(--radius);
  border: 1px solid var(--green-300);
}

.tag.tag-danger {
  background-color: var(--red-200);
  color: var(--red-800);
  border-radius: var(--radius);
  border: 1px solid var(--red-300);
}

.tag.tag-warning {
  background-color: var(--yellow-200);
  color: var(--orange-800);
  border-radius: var(--radius);
  border: 1px solid var(--yellow-300);
}
