@import 'app/styles/utils/type.scss';

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: var(--border-darken);
  stroke-width: 0.05rem;
}

.recharts-cartesian-axis-tick-value {
  padding-top: calc(var(--gw) / 4);
  fill: var(--text-secondary);
  @include textTiny;
}
