* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

:focus {
  outline: none;
}
