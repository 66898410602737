.breadcrumbs {
  display: inline-flex;
  margin: 2rem 0 0 0;
  padding: 0;
  list-style: none;
  font-size: 0.9rem;
  color:var(--grey-900);

  a {
    text-decoration: none;
    color: var(--grey-900) !important;
    &:hover {
      text-decoration:underline;
    }
  }

  li:last-child {
    color:var(--text-primary);
  }

  > li,
  > div {
    padding:0;
  }

  > li::after,
  > div::after {
    content: '/';
    padding:0 calc(var(--gw) / 3);
    color:var(--grey-900);
  }

  > li:last-child::after,
  > div:last-child::after {
    content: '';
    padding-left: calc(var(--gw) / 2);
  }
}