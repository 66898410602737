@mixin shadow($shadow-size) {
  @if $shadow-size == 0 {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
  } @else if $shadow-size == 1 {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  } @else if $shadow-size == 2 {
    box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.2);
  } @else if $shadow-size == 3 {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

@for $i from 0 through 3 {
  .shadow#{$i} {
    @include shadow($i);
  }
}
