
@mixin xs() {
    @media screen and (min-width: 20rem) {
      @content;
    }
  }
  
  @mixin xs-only() {
    @media screen and (max-width: 30rem) {
        @content;
    }
  }
  
  @mixin sm() {
    @media screen and (min-width: 30rem) {
        @content;
    }
  }
  
  @mixin sm-only() {
    @media screen and (min-width: 30rem) and (max-width: 40rem) {
        @content;
    }
  }
  
  @mixin md() {
    @media screen and (min-width: 40rem) {
        @content;
    }
  }
  
  @mixin md-only() {
    @media screen and (min-width: 40rem) and (max-width: 80rem) {
        @content;
    }
  }
  
  @mixin lg() {
    @media screen and (min-width: 80rem) {
        @content;
    }
  }
  
  @mixin lg-only() {
    @media screen and (min-width: 80rem) and (max-width: 105rem) {
        @content;
    }
  }
  
  @mixin xl() {
    @media screen and (min-width: 105rem) {
      @content;
    }
  }
  
  @mixin xl-only() {
    @media screen and (min-width: 105rem) and (max-width: 120rem) {
      @content;
    }
  }
  
  @mixin xxl() {
    @media screen and (min-width: 120rem) {
      @content;
    }
  }
  
  @mixin xxl-only() {
    @media screen and (min-width: 120rem) and (max-width: 200rem) {
      @content;
    }
  }
  
  @mixin xxxl() {
    @media screen and (min-width: 200rem) {
      @content;
    }
  }