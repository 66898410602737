// defaults
@import './styles/defaults/reset';
@import './styles/defaults/variables';
@import './styles/defaults/type';
@import './styles/defaults/card';
@import './styles/defaults/breadcrumbs';
@import './styles/defaults/list-group';
@import './styles/defaults/tag';
@import './styles/defaults/chart';
@import './styles/defaults/empty-state.scss';
@import './styles/defaults/breakpoints.scss';
@import './styles/defaults/grid.scss';

* {
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-bottom {
  position: relative;
}

.fade-bottom:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
  width: 100%;
  height: 1rem;
}

#root {
  overflow: visible;
  height: 100%;
  font-weight: 400;
}

.my0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.text-cap:first-letter {
  text-transform: uppercase;
}

.select__options {
  max-height: 12rem;
  overflow-y: scroll;
}

.centerContent,
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded {
  border-radius: var(--radius);
}

hr {
  width: 100%;
  border: 0;
  height: 0;
  border-top: 0;
  border-bottom: 1px solid var(--grey-300);
  margin: var(--gw);
}

.help-text {
  text-align: inherit;
}

a:hover {
  text-decoration: none;
}

a:active,
a:focus,
a:visited {
  color: inherit;
}

input[type='time'] {
  font-family: inherit;
}

.app-loading {
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc(var(--gw) * 2.5);
}

.Toastify {
  &__toast {
    background-color: white !important;
    &-body {
      margin: 0 0 0 1rem !important;
    }
    &-container {
      z-index: 99999 !important;
    }
  }
}

.table-header-datetime {
  min-width: 13rem !important;
}

.page {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  @media screen and (max-width: 1200px) {
    padding-left: var(--gw);
    padding-right: var(--gw);
  }

  .page__view {
    position: relative;
    padding: calc(var(--gw) / 2) calc(var(--gw) * 2) 0 calc(var(--gw) * 2);
    margin-bottom: 0;
    border-radius: 0;
    border: none !important;
    overflow-x: hidden;
    @media screen and (max-width: 1200px) {
      padding: var(--gw) calc(var(--gw) * 2);
    }
  }
  &__sidebar {
    margin-bottom: calc(var(--gw) * 3.5);
    &__header {
      @include text;
      padding-left: var(--gw);
      padding-right: var(--gw);
      margin-bottom: calc(var(--gw) * 1.5);
    }
  }
}

.page-investor-details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: calc(var(--gw) * 3.5);
  padding: 0 calc(var(--gw) * 4);
  & .page__sidebar {
    margin-bottom: 0;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.inputContainer {
  margin-bottom: var(--gw);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  width: 100%;
  padding-right: calc(var(--gw) * 2.75);
  padding-left: calc(var(--gw) * 1.25);
  padding-top: var(--gw);
  padding-bottom: var(--gw);
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--radius);
  border-color: #eaeaea;
  background-color: white;
  @include textSmall;
}

.input:focus {
  border-color: var(--primary);
}

.inputWithError * {
  border-color: var(--danger) !important;
}

.inputWithError .helperText {
  color: var(--danger) !important;
}

.helperText {
  margin-left: calc(var(--gw) * 1.5);
  margin-top: calc(var(--gw) / 2);
  @include textTiny;
}

.help-error {
  text-transform: none !important;
  padding: 0 !important;
}

/* Form UI */

.form {
  width: 100%;

  &--page {
    background-color: white;
    border: 1px solid var(--border-light);
    padding: calc(var(--gw) * 2);
    border-radius: calc(var(--radius) * 1.5);
  }

  &__header {
    margin-bottom: calc(var(--gw) * 2);

    h1 {
      @include h3;
      margin-bottom: var(--gw);
    }
    p {
      @include textSmall;
      max-width: 50rem;
      color: var(--text-secondary);
    }
  }

  &__section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    margin: 0 0 var(--gw) 0;
    padding-bottom: var(--gw);
    border-bottom: 1px solid var(--grey-300);
    &__title {
      width: 25%;
      padding-right: calc(var(--gw) * 3);
      h2 {
        @include h6;
        margin-bottom: var(--gw);
      }
      p {
        @include textSmall;
        color: var(--text-secondary);
      }
    }
    &__content {
      width: 75%;
      label {
        @include textSmall;
        margin: 0 0 var(--gw) 0;
      }
    }
  }

  &__panel {
    cursor: pointer;
    display: inline-flex;
    width: 100%;
    padding: var(--gw);
    background-color: var(--grey-300);
    border-radius: var(--radius);
    margin: 0 auto;
    text-align: left;
    p {
      @include textSmall;
      color: var(--text-secondary);
    }
    .icon {
      color: var(--grey-800);
      vertical-align: center;
    }
    .btn {
      width: -moz-fit-content;
      width: fit-content;
    }
  }

  &__separator {
    display: block;
    position: relative;
    width: 100%;
    padding: calc(var(--gw) * 1.5) 0;
    text-align: center;
    font-variant: small-caps;
    color: var(--text-secondary);
    @include textSmall;
    > span {
      background-color: white;
      padding: 0 var(--gw);
    }
    z-index: 1;
    &:after {
      z-index: -1;
      content: ' ';
      display: block;
      width: 100%;
      position: relative;
      top: -0.65rem;
      border: 1px solid var(--grey-500);
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;
  }
}

.modal__container {
  animation: 0.25s ease-out 0s 1 fadeIn;
  * {
    animation: 0.5s ease-out 0s 1 fadeIn;
  }
}

/* Horizontal Bar Chart */

.chart__horizontal-bar-chart span:first-of-type {
  max-width: 14rem;
}
.list-errors {
  list-style: none;
  margin: 1rem 0 1rem 1.25rem;
  line-height: 1.5;
  > li:before {
    content: '✖︎';
    margin-right: 0.5rem;
    font-weight: 700;
    color: var(--red-500) !important;
    font-size: 14px;
  }
}

/* Token actions view*/

.token-actions-drawer {
  .tabs {
    position: relative;
    width: 100%;
    height: 3.5rem;
  }
  .tab-item {
    button {
      font-size: 1rem !important;
      margin-top: 0.45rem;
    }
  }
  .tab-item--active {
    button {
      color: var(--primary);
      font-weight: 500;
    }
  }
  .table > thead th {
    vertical-align: middle;
  }
  .table-type-header {
    border: solid;
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
    border-width: 1px 1px 0 1px;
    border-color: var(--grey-500);
    justify-content: space-between;
  }
  .help-error {
    margin: 0;
    padding: 0;
  }
  .help-info {
    display: none;
  }
}

// Hotfix for SP-1846
// can be removed one these values
// are in the ui lib and deisgn system respectivly.
.drawer {
  z-index: 9888 !important;
}

ds-toast {
  z-index: 9999 !important;
}

/* Requests view */

.requests__tabs {
  background-color: var(--grey-100);
  border-bottom: 1px solid var(--grey-500);
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  ds-tabs {
    button {
      border-bottom: 0px !important;
    }

    .tab--warning {
      border-bottom: 2px solid var(--orange-500) !important;
      * {
        font-weight: 500;
        color: var(--orange-600);
        fill: var(--orange-600);
      }
      &:hover * {
        color: var(--orange-600) !important;
        fill: var(--orange-600) !important;
      }
    }

    .tab--neutral {
      border-bottom: 2px solid var(--black) !important;
      * {
        font-weight: 500;
        color: var(--black);
        fill: var(--black);
      }
    }
  }

  & + p {
    margin-top: calc(var(--gw) * 7);
    margin-bottom: calc(var(--gw) * -4);
  }
}

/* Token settings */

#marketplace-settings,
#token-settings {
  background-color: white;
  width: 100%;
  height: 100%;
  ds-input-checkbox[is-checked] label {
    font-weight: 500;
  }
}

.web3modal-modal-lightbox {
  z-index: 1000000 !important; /* higher than the TokenActionsDrawer */
}

.modal {
  z-index: 5000 !important;
}

#token-transactions {
  ds-table .filter-container .help-error {
    display: none;
  }
}

#transactionsView {
  height: 100%;
}

#requests {
  overflow: hidden;
}

.auth-container__background {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}
.auth-container__foreground {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: calc(var(--gw) * 2.75);
}
.auth-container__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.auth__button-primary {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: calc(var(--gw) * 1.2);
}
.auth__button-secondary {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.auth__footer {
  position: absolute;
  bottom: calc(var(--gw) * -2);
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: calc(var(--gw) / 3) calc(var(--gw) / 2);
  color: var(--grey-900);
  background-color: var(--grey-300);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}
.auth__terms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  color: var(--grey-900);
  font-size: 0.79012346rem;
  line-height: 1.3rem;
}
.auth__terms .form-group {
  width: unset;
}

.auth-container__background::after {
  opacity: 0 !important;
}

.auth-container__foreground {
  height: 100vh !important;
}

.auth-container__content {
  padding: 2rem !important;
  height: 100vh !important;
  overflow-y: scroll !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.page__view {
  border: 1px solid var(--grey-400) !important;
}

.dialog__container {
  ds-tag {
    .icon {
      display: flex;
    }
  }
  ds-select-simple {
    input[readonly] {
      visibility: hidden;
    }
  }
}

//Sidebar View
ds-token-selector .token-selector__menu {
  top: 2.8rem;
  z-index: 100;
  left: -2.5rem;
  width: 19rem;
  @include sm() {
    left: 0rem;
    width: 25rem;
  }
}
ds-token-selector .token-selector__control {
  background-color: white;
  padding: calc(var(--gw) / 3) var(--gw);
  width: 100%;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: var(--coolGrey-100);
  }
  ds-icon[name='point'] {
    margin-left: auto;
  }
}

.total-payment {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__tooltip {
    display: flex;
    &--investment {
      color: var(--coolGrey-500);
      flex: 0 1;
      text-align: left;
    }
    &--fee {
      margin-left: 0.5rem;
    }
  }
  &__badge {
    padding: 0.4rem;
    background-color: var(--blue-100);
    border-radius: var(--radius);
    cursor: pointer;
    &:hover {
      background-color: var(--blue-200);
    }
  }
}

#subscription-orders,
#redemption-orders {
  padding: calc(var(--gw) * 1.5);
  .dialog__container {
    padding: 1.5rem 2.5rem;
    max-width: 45rem;
    margin: 0 1rem;
    width: 100%;
    & > div {
      min-height: inherit;
    }
    header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      span {
        @include textTiny;
        color: var(--coolGrey-400);
      }
    }
    footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
      align-items: center;
      & > *:not(:last-child) {
        margin-right: 0.5rem;
      }
      & ds-button {
        order: 1;
      }
      & > :not(ds-button) {
        flex-grow: 1;
      }
    }
    h3 {
      @include h3;
    }
    .order-details {
      margin-bottom: calc(var(--gw));
      &__section {
        margin-bottom: calc(var(--gw)) 0;
        border-bottom: 1px solid var(--grey-400);
        &:last-child {
          border-bottom: none;
        }
        &__item {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          margin: calc(var(--gw) * 0.75) 0;
          @include textTiny;
          @include md() {
            align-items: center;
            flex-direction: row;
          }
          &--key {
            display: flex;
            flex: 1 0 100%;
            align-items: center;
            color: var(--coolGrey-500);
            @include md() {
              flex: 1 0 50%;
            }
            ds-tooltip {
              margin-left: 0.6rem;
              margin-top: 0.3rem
            }
          }
          &--value {
            flex: 1 0 100%;
            color: var(--coolGrey-700);
            @include md() {
              flex: 1 0 50%;
            }
            code {
              @include textTiny;
            }
          }
        }
      }
    }
    article {
      display: flex;
      flex-direction: column;
      min-height: inherit;
      & > ds-tabs {
        justify-content: center;
        margin-bottom: 1.5rem;
        ds-tab {
          flex-grow: 1;
          @include textSmall;
          padding: 1rem;
          color: var(--coolGrey-400);
        }
      }
    }
    ds-tabs {
      a {
        color: var(--coolGrey-400);
        ds-icon svg {
          fill: var(--coolGrey-400);
        }
      }
      a:hover {
        text-decoration: none;
        color: var(--black);
        ds-icon svg {
          fill: var(--black);
        }
      }
      a.active {
        color: var(--primary);
      }
    }
    ds-loader {
      display: flex;
      justify-content: center;
      height: 25rem;
      align-items: center;
    }
  }
  .trade-date-box {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0rem;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid var(--grey-400);
    background: var(--grey-100);
    margin-bottom: 0.75rem;
    gap: 0.75rem;
    @include textTiny;
    &__item {
      display: flex;
      align-items: center;
      padding: 0rem 0.75rem;
      width: 100%;
      &--key {
        display: flex;
        align-items: center;
        & p {
          color: var(--coolGrey-500);
        }
        flex: 1 0 100%;
        @include md() {
          flex: 1 0 50%;
        }
        & ds-icon {
          margin-left: 0.5rem;
        }
      }
      &--value {
        color: var(--coolGrey-700);
        flex: 1 0 100%;
        @include md() {
          flex: 1 0 50%;
        }
      }
    }
  }
}

.loading-ellipsis::after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
